/* You can add global styles to this file, and also import other style files */
@import '@syncfusion/ej2-base/styles/bootstrap5.css';
@import '@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '@syncfusion/ej2-grids/styles/bootstrap5.css';
@import '@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '@syncfusion/ej2-popups/styles/bootstrap5.css';
@import "@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import '@syncfusion/ej2-pivotview/styles/bootstrap5.css';
