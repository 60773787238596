@use '@angular/material' as mat;
@use 'typography' as *;

// Custom Palette created
$primary-hues: (
  0: white,
  50: var(--color-blue-50),
  100: var(--color-blue-100),
  200: var(--color-blue-200),
  300: var(--color-blue-300),
  400: var(--color-blue-400),
  500: var(--color-blue-500),
  600: var(--color-blue-600),
  700: var(--color-blue-700),
  800: var(--color-blue-800),
  900: var(--color-blue-900),
  A100: var(--color-blue-100),
  A200: var(--color-blue-100),
  A400: var(--color-blue-700),
  A700: var(--color-blue-700),
  contrast: (
    0: var(--color-surface-900),
    50: var(--color-surface-900),
    100: var(--color-surface-800),
    200: var(--color-surface-700),
    300: var(--color-surface-600),
    400: var(--color-surface-600),
    500: var(--color-surface-600),
    600: var(--color-surface-50),
    700: var(--color-surface-50),
    800: var(--color-surface-50),
    900: var(--color-surface-50),
    A100: var(--color-surface-900),
    A200: var(--color-surface-800),
    A400: var(--color-surface-600),
    A700: var(--color-surface-300),
  ),
);

$accent-hues: (
  0: white,
  50: var(--color-green-50),
  100: var(--color-green-100),
  200: var(--color-green-200),
  300: var(--color-green-300),
  400: var(--color-green-400),
  500: var(--color-green-500),
  600: var(--color-green-600),
  700: var(--color-green-700),
  800: var(--color-green-800),
  900: var(--color-green-900),
  A100: var(--color-green-100),
  A200: var(--color-green-100),
  A400: var(--color-green-600),
  A700: var(--color-green-600),
  contrast: (
    0: var(--color-surface-900),
    50: var(--color-surface-900),
    100: var(--color-surface-800),
    200: var(--color-surface-700),
    300: var(--color-surface-600),
    400: var(--color-surface-600),
    500: var(--color-surface-600),
    600: var(--color-surface-50),
    700: var(--color-surface-50),
    800: var(--color-surface-50),
    900: var(--color-surface-50),
    A100: var(--color-surface-900),
    A200: var(--color-surface-800),
    A400: var(--color-surface-600),
    A700: var(--color-surface-300),
  ),
);

$warning-hues: (
  0: white,
  50: var(--color-orange-50),
  100: var(--color-orange-100),
  200: var(--color-orange-200),
  300: var(--color-orange-300),
  400: var(--color-orange-400),
  500: var(--color-orange-500),
  600: var(--color-orange-600),
  700: var(--color-orange-700),
  800: var(--color-orange-800),
  900: var(--color-orange-900),
  A100: var(--color-orange-100),
  A200: var(--color-orange-100),
  A400: var(--color-orange-600),
  A700: var(--color-orange-600),
  contrast: (
    0: var(--color-surface-900),
    50: var(--color-surface-900),
    100: var(--color-surface-800),
    200: var(--color-surface-700),
    300: var(--color-surface-600),
    400: var(--color-surface-600),
    500: var(--color-surface-600),
    600: var(--color-surface-50),
    700: var(--color-surface-50),
    800: var(--color-surface-50),
    900: var(--color-surface-50),
    A100: var(--color-surface-900),
    A200: var(--color-surface-800),
    A400: var(--color-surface-600),
    A700: var(--color-surface-300),
  ),
);

$primary-palette: mat.define-palette($primary-hues, 800, 600, 900);
$accent-palette: mat.define-palette($accent-hues);
$warn-palette: mat.define-palette($warning-hues);

$light-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
      typography: $kpi4me-typography
    )
);
@include mat.all-component-themes($light-theme);

.light-theme {
  @include mat.all-component-densities(minimum);

  --frame: var(--color-surface-50);
  --original: var(--color-blue-100);
  --background: white;
  --highlighted: var(--color-orange-100);
  --input-background: var(--color-blue-50);
  --text-dark: var(--color-surface-500);
  --text-bright: white;
  --text-gray: var(--color-surface-200);
  --menu-background: white;
  --kpi: var(--color-blue-50);
  --kpi-group: var(--color-orange-50);
  --header: var(--color-orange-50);
  --kpi-benchmark: white;
  --disabled-option: var(--color-surface-400);
  --hover: var(--color-surface-100);
  --background-blue: var(--color-blue-200);
  --table-header-color: var(--color-blue-100);
  --toolbar-text: var(--color-surface-50);
  --sidebar-text: var(--color-blue-800);
  --open-close-icon: var(--color-surface-500);
  --sidebar-hover: var(--color-surface-50);
  --sidebar-not-hover: var(--color-surface-500);
  --sidebar-hover-background: var(--color-blue-600);
  --divider: var(--color-surface-100);
  --side-menu-background: white;
  --toolbar-button-background: var(--color-blue-900);
  --select-background: var(--color-blue-900);
  --toolbar-button-outline: var(--color-surface-50);
  --toolbar-icon-background: var(--color-blue-900);
  --toolbar-icon-outline: var(--color-surface-50);
  --toolbar-background: var(--color-blue-800);
  --toolbar-border: var(--color-blue-800);
  --imported-cell: var(--color-surface-50);
  --snackbar-background: var(--color-surface-500);
  --snackbar-button: var(--color-surface-600);
  --snackbar-text: var(--color-surface-100);
  --default-value-text: rgba(0, 0, 0, 0.5);
  --help-background: var(--color-orange-100);

  // Bootstrap Calendar
  --bs-body-color: var(--text-dark);
  --bs-body-bg: white;
  --bs-tertiary-bg: var(--color-surface-50);
  --bs-link-color: var(--color-blue-500);
  --bs-link-hover-color: var(--color-blue-600);
  --bs-info: var(--color-blue-400);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-blue-500);

  .bg-primary {
    background-color: var(--color-blue-500) !important;
  }

  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb(63, 75, 87)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
  }

  // Mat-menu
  --mat-menu-item-label-text-color: var(--text-dark);
  --mat-menu-item-icon-color: var(--text-dark);
  --mat-menu-item-hover-state-layer-color: var(--color-blue-800);
  --mat-menu-item-focus-state-layer-color: var(--color-blue-700);
  --mat-menu-container-color: var(--color-surface-50);

  .mat-mdc-menu-item:not([disabled]).cdk-program-focused,
  .mat-mdc-menu-item:not([disabled]).cdk-keyboard-focused,
  .mat-mdc-menu-item:not([disabled]).mat-mdc-menu-item-highlighted,
  .mat-mdc-menu-item:not([disabled]):hover {
    color: var(--text-bright);

    --mat-menu-item-icon-color: var(--text-bright);
  }

  // Checkbox
  --mdc-checkbox-selected-checkmark-color: var(--text-bright);

  .mat-mdc-checkbox.mat-accent,
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: var(--text-bright);
  }

  // Buttons
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-raised-button.mat-accent,
  .mat-mdc-raised-button.mat-warn,
  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-unelevated-button.mat-accent,
  .mat-mdc-unelevated-button.mat-warn,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-fab.mat-accent,
  .mat-mdc-fab.mat-warn,
  .mat-mdc-mini-fab.mat-primary,
  .mat-mdc-mini-fab.mat-accent,
  .mat-mdc-mini-fab.mat-warn {
    color: var(--text-bright);
  }

  // Progress Bar
  .mdc-linear-progress__buffer-bar {
    opacity: 0.4;
  }

  // Date Picker
  kpi4me-date-picker {
    mat-button-toggle {
      color: var(--color-surface-50);

      --mat-standard-button-toggle-selected-state-text-color: var(--color-surface-50);
    }
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-pseudo-checkbox, .mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox {
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-surface-50);
  }

  // Slide-Toggle
  @mixin set-handle-colors($color) {
    --mdc-switch-selected-hover-handle-color: #{mat.get-theme-color($light-theme, $color, 700)};
    --mdc-switch-selected-focus-handle-color: #{mat.get-theme-color($light-theme, $color, 700)};
    --mdc-switch-selected-pressed-handle-color: #{mat.get-theme-color($light-theme, $color, 700)};
  }

  .mat-mdc-slide-toggle {
    &.mat-accent {
      @include set-handle-colors(accent);
    }

    &.mat-warn {
      @include set-handle-colors(warn);
    }

    &.mat-primary {
      @include set-handle-colors(primary);
    }
  }

  // dialog
  [mat-dialog-title] {
    background-color: var(--color-blue-800);
    --mdc-dialog-subhead-color: var(--color-surface-50);
  }

  // confirm icon
  app-confirm-icon-button .mat-mdc-raised-button {
    --mdc-protected-button-label-text-color: var(--color-surface-50);
    --mdc-protected-button-container-color: var(--color-blue-800);

    mat-icon {
      color: var(--color-surface-50);
    }
  }


  .lm_controls .lm_popout {
    background-image: url(../assets/svg_new/splitscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_maximised .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_popin .lm_icon {
    background-image: url(../assets/svg_new/edit_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .red-icon {
    color: var(--color-orange-500) !important;

    .st5,
    .st8,
    .st6 {
      stroke: var(--color-orange-500) !important;
    }

    .st6,
    .st7 {
      fill: var(--color-orange-500);
    }
  }

  .mat-mdc-tab.mdc-tab.mdc-tab--active {
    background-color: var(--color-blue-300);
  }
}

// autofill background color

input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-dark);
  -webkit-background-clip: text;
}

// Accept, Decline, Reset icons

.cancel-icon {
  mat-icon {
    color: var(--color-orange-500);
    width: 35px;
    height: 35px;
  }
}

.save-icon {
  .st5 {
    stroke: var(--color-green-500);
  }
}

.confirm-icon {
  color: var(--color-blue-600);
}

.mat-button-disabled {
  color: var(--disabled-option) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--disabled-option) !important;
  }

  .st6,
  .st7 {
    fill: var(--disabled-option);
  }
}

.reload-icon {
  .st5 {
    stroke: var(--color-blue-600);
  }
}

.light-icon {
  color: var(--toolbar-icon-outline) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--toolbar-icon-outline) !important;
  }

  .st6,
  .st7 {
    fill: var(--toolbar-icon-outline);
  }
}

.green-icon {
  color: var(--color-green-500) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--color-green-500) !important;
  }

  .st6,
  .st7 {
    fill: var(--color-green-500);
  }
}

.yellow-icon {
  color: var(--color-blue-300) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--color-blue-300) !important;
  }

  .st6,
  .st7 {
    fill: var(--color-blue-300);
  }
}

.black-icon {
  color: var(--color-surface-900) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--color-surface-900) !important;
  }

  .st6,
  .st7 {
    fill: var(--color-surface-900);
  }
}

.blue-icon {
  color: var(--color-blue-600) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--color-blue-600) !important;
  }

  .st6,
  .st7 {
    fill: var(--color-blue-600);
  }
}

.sidebar-icon {
  color: var(--sidebar-text) !important;

  .st5,
  .st8,
  .st6 {
    stroke: var(--sidebar-text) !important;
  }

  .st6,
  .st7 {
    fill: var(--sidebar-text);
  }
}

.smaller-icon {
  width: 20px;
  height: 20px;
}

.custom-placeholder {
  color: var(--text-dark);
}

// Icon classes

.st5 {
  fill: none;
  stroke: var(--icon);
  stroke-width: 25;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st6 {
  fill: var(--icon);
  stroke: var(--icon);
  stroke-width: 25;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st7 {
  fill: var(--icon);
}

.st8 {
  fill: none;
  stroke: var(--icon);
  stroke-width: 25;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 33;
}

.transparent .mat-mdc-dialog-container {
  box-shadow: none;
  background: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-surface-200);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-surface-300);
}

