:root {
  --primary: var(--color-green-500);
}

ngb-tooltip-window .tooltip-inner {
  white-space: pre-line;
}

// Calendar

.ngb-dp-navigation-chevron {
  //color: var(--toolbar-BG);
}

.custom-day,
.ngb-dp-month-name,
.dropdown-menu {
  //color: var(--text-dark) !important;
}

.ngb-dp-month-name,
.ngb-dp-header,
.dropdown-menu,
.ngb-dp-weekdays {
  //background-color: var(--frame) !important;
}

// Bootstrap popover

.popover-body {
  background-color: var(--frame);
}

// Bootstrap tooltips

.email-confirm-tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    max-width: unset !important;
  }
}

.custom-bootstrap-tooltip,
.email-confirm-tooltip {
  .tooltip-inner {
    background-color: var(--frame);
    color: var(--text-dark);
  }

  &.bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: var(--frame);
    }
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: var(--frame);
  }

  &.bs-tooltip-right .arrow::before {
    border-right-color: var(--frame);
  }

  &.bs-tooltip-left .arrow::before {
    border-left-color: var(--frame);
  }
}

.smaller-form-control {
  padding: 0.175rem 0.45rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem !important;
}

.table > :not(caption) > * > * {
  padding: unset;
}

.table {
  margin-bottom: unset;
}

label {
  margin-bottom: unset;
}
