@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

.customTreeStyle {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  color: var(--text-dark);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  .node-wrapper {
    margin: 2px 0;
    //height: 18px;
  }

  .node-content-wrapper {
    padding: 0 0;
  }

  .node-content-wrapper,
  .tree-children {
    position: relative;
  }

  .node-content-wrapper::before,
  .tree-children::after {
    content: '';
    position: absolute;
  }

  // Remove the border on the root elements
  .tree-node-level-1
  > tree-node-wrapper
  > .tree-node-wrapper
  > div
  > .node-wrapper
  > .node-content-wrapper::before {
    border: unset;
  }

  .node-content-wrapper::before {
    border-bottom: 1px solid var(--color-surface-100);
    border-left: 1px solid var(--color-surface-100);
    // height: var(--nodeContentWrapperHeight);
    // top: var(--nodeContentWrapperTop);
    height: 10010px;
    top: -10001px;
    width: 15px;
    left: -28px;
    pointer-events: none;
  }

  .node-content-wrapper-leaf::before {
    left: -31px !important;
  }

  .extended-node-content-wrapper::before {
    left: -44px !important;
  }

  .tree-node-level-1
  > tree-node-wrapper
  > .node-wrapper
  > .node-content-wrapper::before {
    display: none;
  }

  .tree-node-level-1 {
    position: relative;
    left: -18px;
  }

  .tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
    width: 25px;
  }

  .tree-children::after {
    //border-left: 1px solid var(--color-surface-100);
    height: 100%;
    top: -28px;
    left: 5px;
  }

  tree-node:last-child > .tree-node > .tree-children::after {
    border-left: none !important;
  }

  .toggle-children-wrapper {
    /* padding: 2px 3px 5px 1px; */
    padding: unset;
    margin: 0px -3px;
    left: -1px;
    position: relative;
  }

  .toggle-children {
    z-index: 1;
    height: 19px;
    width: 19px;
    background-size: contain;
    display: inline-block;
    position: relative;
    top: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../../nucleus/src/lib/assets/svg_new/keyboard_arrow_right_FILL0_wght300_GRAD0_opsz24.svg');
  }

  tree-node-expander {
    width: 13px;
    height: 100%;
    margin-left: 21px;
  }

  /* styles for '...' */
  tree-node-content {
    /* hide text if it more than N lines  */
    //max-width: 9.7rem;
    text-overflow: ellipsis;
    //overflow: hidden;
    white-space: nowrap;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    //line-height: 18px;
    /* max-height = line-height (18px) * lines max number (2) */
    //max-height: 36px;
    /* fix problem when last visible word doesn't adjoin right side
    text-align: justify; */
    /* place for '...' */
    //margin-right: -1em;
    //padding-right: 1em;
    display: flex;
    align-items: center;
    word-break: break-word;
    //max-width: 200px;
    //margin-right: -1em;
    > span {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }

    .treeNodeRow {
      display: flex;
      align-items: center;
      height: 16px;

      span {
        height: 100%;

        .treeNodeIcon {
          height: 100%;
        }
      }
    }

    .treeNodeIcon {
    }
  }

  // ngx-popper component style
  .ngxp__inner.ng-star-inserted {
    font-size: 11px;
  }

  .ngxp__container {
    background: var(--color-surface-500);
    color: white;
  }

  .node-drop-slot {
    height: 5px;
  }

  .is-dragging-over {
    height: 20px;
  }
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  background-color: var(--mat-option-hover-state-layer-color);
}

.node-content-wrapper-active.node-content-wrapper-focused {
  background-color: var(--mat-option-focus-state-layer-color);
}

.reference-selection-tree {
  // Class for the tree in the reference selection editor component
  /**
  Either show the checkbox for leaf nodes, or not when they are no leafes
  **/
  .tree-node {
    .tree-node-checkbox {
      visibility: hidden;
    }
  }

  .tree-node-leaf {
    .tree-node-checkbox {
      visibility: visible;
    }

    tree-node-checkbox {
      input {
        cursor: pointer;
      }
    }
  }
}

.tree-node-active {
  > tree-node-wrapper {
    .node-action,
    .delete-node {
      display: flex !important;
    }
  }
}
