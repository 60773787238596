@use '@angular/material' as mat;
@use 'typography' as *;
@use 'theme-light' as *;

$primary-palette-dark: mat.define-palette(
    map-merge($primary-hues, (
      contrast: (
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: var(--color-surface-900),
        A200: var(--color-surface-900),
        A400: var(--color-surface-900),
        A700: var(--color-surface-900),
      )),
    ),
    600, 600, 900
);

$accent-palette-dark: mat.define-palette(
    map-merge($accent-hues, (
      contrast: (
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: var(--color-surface-900),
        A200: var(--color-surface-900),
        A400: var(--color-surface-900),
        A700: var(--color-surface-900),
      )
    )),
    600, 500, 700
);

$warn-palette-dark: mat.define-palette(
    map-merge($warning-hues, (
      contrast: (
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: var(--color-surface-900),
        A200: var(--color-surface-900),
        A400: var(--color-surface-900),
        A700: var(--color-surface-900),
      ))
    )
);

$dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary-palette-dark,
        accent: $accent-palette-dark,
        warn: $warn-palette-dark,
      ),
      typography: $kpi4me-typography
    )
);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include mat.all-component-densities(minimum);

  --frame: var(--color-surface-700);
  --original: var(--color-blue-900);
  --background: var(--color-surface-800);
  --highlighted: var(--color-surface-400);
  --input-background: var(--color-surface-500);
  --text-dark: var(--color-surface-50);
  --text-bright: var(--color-surface-500);
  --text-gray: var(--color-surface-200);
  --menu-background: var(--color-surface-500);
  --kpi: var(--color-surface-500);
  --kpi-group: var(--color-surface-500);
  --kpi-benchmark: var(--color-surface-400);
  --header: var(--color-surface-500);
  --form-field-BG: var(--color-surface-700);
  --disabled-option: var(--color-surface-200);
  --hover: var(--color-surface-300);
  --background-blue: var(--color-blue-600);
  --table-header-color: var(--color-surface-600);
  --toolbar-text: var(--color-surface-50);
  --sidebar-text: var(--color-surface-50);
  --open-close-icon: var(--color-surface-200);
  --sidebar-hover: var(--color-surface-50);
  --sidebar-not-hover: var(--color-surface-200);
  --sidebar-hover-background: var(--color-orange-600);
  --divider: var(--color-surface-600);
  --side-menu-background: var(--color-surface-800);
  --toolbar-button-background: var(--color-surface-700);
  --select-background: var(--color-surface-700);
  --toolbar-button-outline: var(--color-surface-50);
  --toolbar-icon-background: var(--color-surface-700);
  --toolbar-icon-outline: var(--color-surface-50);
  --toolbar-background: var(--color-surface-600);
  --toolbar-border: var(--color-surface-600);
  --imported-cell: var(--color-surface-600);
  --snackbar-background: var(--color-surface-100);
  --snackbar-button: var(--color-surface-600);
  --snackbar-text: var(--color-surface-500);
  --default-value-text: rgba(255, 255, 255, 0.5);
  --help-background: var(--color-orange-900);

  // Bootstrap Calendar
  --bs-body-color: var(--text-dark);
  --bs-body-bg: var(--color-surface-700);
  --bs-tertiary-bg: var(--color-surface-500);
  --bs-link-color: var(--color-blue-500);
  --bs-link-hover-color: var(--color-blue-600);
  --bs-info: var(--color-blue-400);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-blue-500);

  .bg-primary {
    background-color: var(--color-blue-500) !important;
  }

  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb(246, 247, 249)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
  }

  // mat-sidenav
  --mat-sidenav-container-background-color: var(--background);

  // Mat-menu
  --mat-menu-item-label-text-color: var(--text-dark);
  --mat-menu-item-icon-color: var(--text-dark);
  --mat-menu-item-hover-state-layer-color: var(--color-blue-700);
  --mat-menu-item-focus-state-layer-color: var(--color-blue-700);
  --mat-menu-container-color: var(--color-surface-600);

  .mat-mdc-menu-item:not([disabled]).cdk-program-focused,
  .mat-mdc-menu-item:not([disabled]).cdk-keyboard-focused,
  .mat-mdc-menu-item:not([disabled]).mat-mdc-menu-item-highlighted,
  .mat-mdc-menu-item:not([disabled]):hover {
    color: var(--text-dark)
  }

  // Checkbox
  --mdc-checkbox-selected-checkmark-color: var(--text-bright);

  // Buttons
  --mdc-protected-button-label-text-color: var(--text-dark);

  body {
    background: var(--background) !important;
    color: var(--text-dark) !important;
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate::after {
    color: var(--text-dark);
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--text-dark);
  }


  // Progress Bar
  .mdc-linear-progress__buffer-bar {
    opacity: 0.4;
  }

  // Date Picker
  kpi4me-date-picker {
    mat-button-toggle {
      color: var(--color-surface-50);

      --mat-standard-button-toggle-selected-state-text-color: var(--color-surface-50);
    }
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-pseudo-checkbox, .mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox {
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-surface-50);
  }

  // Slide-Toggle
  @mixin set-handle-colors($color) {
    --mdc-switch-selected-hover-handle-color: #{mat.get-theme-color($dark-theme, $color, 700)};
    --mdc-switch-selected-focus-handle-color: #{mat.get-theme-color($dark-theme, $color, 700)};
    --mdc-switch-selected-pressed-handle-color: #{mat.get-theme-color($dark-theme, $color, 700)};
  }

  .mat-mdc-slide-toggle {
    &.mat-accent {
      @include set-handle-colors(accent);
    }

    &.mat-warn {
      @include set-handle-colors(warn);
    }

    &.mat-primary {
      @include set-handle-colors(primary);
    }
  }

  // Tabs
  .mat-mdc-tab-group {
    --mat-tab-header-inactive-label-text-color: var(--text-dark);
  }

  .mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: 0.4;
  }

  // confirm icon
  .mat-mdc-raised-button {
    --mdc-protected-button-label-text-color: var(--color-surface-50);

    mat-icon {
      color: var(--color-surface-50);
    }
  }

  // process header
  .process-header-label {
    color: var(--color-blue-50);
  }


  // Golden layout
  .lm_controls .lm_popout {
    background-image: url(../assets/svg_new/splitscreen_white_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_white_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_maximised .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_exit_white_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_popin .lm_icon {
    background-image: url(../assets/svg_new/edit_white_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .ag-theme-alpine {
    --ag-border-color: var(--color-surface-300);
    --ag-secondary-border-color: var(--color-surface-300);
    --ag-cell-horizontal-border: solid var(--color-surface-300);
  }

  app-table-control div div.optionWrapper {
    border: 1px solid var(--color-surface-300) !important
  }

  .mat-form-field-required-marker {
    color: var(--text-dark);
  }

  .mat-mdc-option {
    color: var(--text-dark);
    background-color: var(--color-surface-600);
  }

  .mat-mdc-option .mat-option-disabled {
    color: var(--disabled-option);
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled),
  .mat-mdc-option:focus:not(.mdc-list-item--disabled),
  .mat-mdc-option.mat-mdc-option-active,
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background-color: var(--highlighted);
    color: var(--text-dark);
  }

  .mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text {
    color: var(--text-dark);
  }

  // text color for selected mat options in a mat select
  .mat-primary .mat-mdc-option .mat-selected:not(.mat-option-disabled) {
    color: var(--text-dark);
  }

  .create-new-option {
    color: var(--text-dark);

  }

  .search-field {
    color: var(--text-dark);
  }

  // Dialog
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--color-surface-50);
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: var(--color-surface-50);
  }

  .upload-wrapper {
    background-color: var(--color-blue-800) !important;
  }

  .red-icon {
    color: var(--color-orange-500) !important;

    .st5,
    .st8,
    .st6 {
      stroke: var(--color-orange-500) !important;
    }

    .st6,
    .st7 {
      fill: var(--color-orange-500);
    }
  }

  // autofill text color

  input:-webkit-autofill {
    -webkit-text-fill-color: var(--text-bright);
  }
}
