// Increased z-index for things like selections inside of popup editors
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  //z-index: 99999 !important;
}

//mat-label {
//  color: var(--text-dark);
//}

//.mat-drawer {
//  background-color: var(--frame) !important;
//}

@mixin mat-ripple-theme($theme) {
  .mat-ripple-element {
    background-color: var(--button-alternative);
  }
}

.mat-step-header {
  //height: 0.5rem !important;
}

.mat-step-header .mat-step-icon-selected {
  background-color: var(--icon-alternative) !important;
}

// Mat-select
.custom-select-panel {
  background: var(--menu-background) !important;
}

.mat-mdc-menu-content {
  .mat-mdc-menu-item-submenu-trigger::after {
    color: var(--text-dark);
  }

  .mat-mdc-menu-item {
    line-height: unset;
    height: 24px;
    min-height: unset;
    display: flex;
    align-items: center;
    margin: 2px 0;
    padding: 0 5px;
    font-size: var(--middle-font-size) !important;
  }

  .mat-mdc-menu-item-submenu-trigger {
    padding-right: 32px;
  }
}

.mat-expansion-panel-body {
  padding: 0.5rem !important;
}

.mat-mdc-select-panel {
  font-size: 14px !important;
  max-width: 420px !important;
}

// mat-icon inside mat-button
.mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
  margin-right: 0 !important;
}

.mat-mdc-option {
  font-size: 14px !important;
  height: 2em !important;
  min-height: unset !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

/** ----------------------------------------------  */

.mat-mdc-dialog-actions {
  // padding: unset!important;
  margin-bottom: unset !important;
  justify-content: center !important;
  padding: 0 !important;
}

/*set the dialog title in center */
.mdc-dialog__title {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 24px !important;
}

.mat-mdc-dialog-container > .ng-star-inserted {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  //justify-content: space-evenly;
}

.mdc-dialog__container {
  display: flex !important;
  justify-content: center !important;
  resize: both;
}

// dialog min height to fit content
.cdk-overlay-pane {
  min-height: fit-content;
}

.mat-mdc-menu-panel {
  min-height: fit-content !important;
}

// center the description
.mat-expansion-panel-header-description {
  flex-grow: 2;
  flex-basis: 0;
}

// expansion panel arrow
.mat-expansion-indicator {
  position: absolute;
  left: 7px;
}

/*Relation Tables Are set a bit higher in y position */
.mat-figure {
  align-items: unset !important;
}

.mat-mdc-button {
  border: 2px solid transparent;
}

/* override the material tooltip class */

// Increase the font size of the tooltip
.mat-mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-size: var(--middle-font-size);
}


.white-space-pre-line {
  white-space: pre-line;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 4px;
}

.mat-mdc-tab-group {
  height: unset;
  flex: 1;
  overflow: auto;
}

.mat-mdc-tab.mdc-tab {
  flex-grow: 0;
  border-bottom: none;
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--color-surface-200);
  background-color: var(--frame);
  color: var(--text-dark);
  margin-right: 1px;
  padding: 0 10px;
  height: var(--mat-tab-header-height) !important;
}

.tabWrapper {
  .mat-mdc-tab-group {
    height: 90%;
  }
}

.mat-mdc-tab-body-wrapper {
  flex: 1;
}

// list item but smaller

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
  &.smaller-list-item {
    height: var(--smaller-list-item-height);
  }
}

.smaller-list-item {
  height: var(--smaller-list-item-height);
  display: flex;
  align-items: center;
}

[mat-dialog-title] {
  cursor: move;
}

mat-icon.mat-icon {
  display: inline-flex;
  align-items: center;
}

.mat-mdc-select-panel {
  min-width: fit-content;
}


.mdc-list-item__primary-text {
  width: 100%;
}

/* ____ Mat Form Fields ____*/

// center mat form field

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  align-items: center;
  display: flex;
}

.mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 5px !important;
}

mat-snack-bar-container {
  --mdc-snackbar-container-color: var(--snackbar-background);
  --mdc-snackbar-supporting-text-color: var(--snackbar-text);
}

custom-snackbar {
  .mdc-button {
    background: var(--snackbar-button);
  }
}

.customized-menu {
  .mat-mdc-menu-content, .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-mdc-menu-panel {
    padding-bottom: 0;
  }

  .mat-menu-title {
    font-weight: 700;
    background-color: var(--frame);
    padding: 5px;
    width: 100%;
    display: block;
  }
}
