:root,
.ns-root {
  --tableIconSize: 18px;
  --small-font-size: 12px;
  --middle-font-size: 14px;
  --big-font-size: 16px;
  --bottom-toolbar-padding: 25px;
  --menu-font-size: 14px;
  --componentHeaderHeight: 30px;
  --smaller-list-item-height: 1.5rem;
  --toolbarHeight: 45px;
  --mat-tab-header-height: 35px;

  --color-surface-50: #F6F7F9; // rgb(246, 247, 249)
  --color-surface-100: #E0E4E9; // rgb(224, 228, 233)
  --color-surface-200: #B3BEC9; // rgb(179, 190, 201)
  --color-surface-300: #8798A8; // rgb(135, 152, 168)
  --color-surface-400: #5F7284; // rgb(95, 114, 132)
  --color-surface-500: #3F4B57; // rgb(63, 75, 87)
  /* base */
  --color-surface-600: #1F252B; // rgb(31, 37, 43)
  --color-surface-700: #191D22; // rgb(25, 29, 34)
  --color-surface-800: #121619; // rgb(18, 22, 25)
  --color-surface-900: #0C0E10; // rgb(12, 14, 16)


  --color-blue-50: #F5FCFE; // rgb(245, 252, 254)
  --color-blue-100: #EBF9FE; // rgb(235, 249, 254)
  --color-blue-200: #CDEFFB; // rgb(205, 239, 251)
  --color-blue-300: #AEE5F9; // rgb(174, 229, 249)
  --color-blue-400: #72D2F5; // rgb(114, 210, 245)
  --color-blue-500: #35BEF0; // rgb(53, 190, 240)
  --color-blue-600: #30ABD8; // rgb(48, 171, 216)
  --color-blue-700: #207290; // rgb(32, 114, 144)
  --color-blue-800: #18566C; // rgb(24, 86, 108)
  --color-blue-900: #103948; // rgb(16, 57, 72)


  --color-green-50: #FAFCF4; // rgb(250, 252, 244)
  --color-green-100: #F5F9EA; // rgb(245, 249, 234)
  --color-green-200: #E6F0C9; // rgb(230, 240, 201)
  --color-green-300: #D7E7A9; // rgb(215, 231, 169)
  --color-green-400: #BAD669; // rgb(186, 214, 105)
  --color-green-500: #9CC428; // rgb(156, 196, 40)
  --color-green-600: #8CB024; // rgb(140, 176, 36)
  --color-green-700: #5E7618; // rgb(94, 118, 24)
  --color-green-800: #465812; // rgb(70, 88, 18)
  --color-green-900: #2F3B0C; // rgb(47, 59, 12)

  --color-orange-50: #FEF8F3; // rgb(254, 248, 243)
  --color-orange-100: #FDF0E8; // rgb(253, 240, 232)
  --color-orange-200: #FBDAC5; // rgb(251, 218, 197)
  --color-orange-300: #F8C3A1; // rgb(248, 195, 161)
  --color-orange-400: #F2975B; // rgb(242, 151, 91)
  --color-orange-500: #ED6A15; // rgb(237, 106, 21)
  --color-orange-600: #D55F13; // rgb(213, 95, 19)
  --color-orange-700: #8E400D; // rgb(142, 64, 13)
  --color-orange-800: #6B3009; // rgb(107, 48, 9)
  --color-orange-900: #472006; // rgb(71, 32, 6)

  --color-red-50: #ffe6e6;
  --color-red-100: #ffb3b4;
  --color-red-200: #ff8082;
  --color-red-300: #E46450;
  --color-red-400: #ff1a1d;
  --color-red-500: #E60005;
  --color-red-600: #b30003;
  --color-red-700: #A51E0F;
  --color-red-800: #4d0001;
  --color-red-900: #1a0000;

  --drk-red-main: var(--color-red-500); // rgb(230, 0, 5)
  --drk-red-light: var(--color-red-300); // rgb(228, 100, 80)
  --drk-red-dark: var(--color-red-700); // rgb(165, 30, 15)
  --drk-blue-dark: #002D55; // rgb(0, 45, 85)
  --drk-blue-main: #008CCD; // rgb(0, 140, 205)
  --drk-blue-var: #2275D0; // rgb(34, 117, 208)
  --drk-blue-light: #EBF5FF; // rgb(235, 245, 255)
  --drk-grey-dark: #554F4A; // rgb(85, 79, 74)
  --drk-grey-main: #B4B4B4; // rgb(180, 180, 180)
  --drk-grey-light: #EFEEEA; // rgb(239, 238, 234)
  --drk-grey-var: #D9D9D9; // rgb(217, 217, 217)

  --button: var(--color-green-100);
  --button-alternative: var(--color-blue-500);
  --toolbar-BG: var(--select-background);
  --toolbar-color: white;
  --icon: var(--color-green-100);
  --icon-alternative: var(--color-blue-500);
  --row-highlighted: var(--color-blue-300);
  --row-highlighted-color: var(--color-surface-500);
  --kpi-select-box: var(--color-blue-500);
  --thumbBG: var(--color-blue-500);
  --scrollbarBG: var(--color-green-100);
  --warning: var(--color-orange-600);
  --success: var(--color-green-600);
}
