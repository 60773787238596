@use '@angular/material' as mat;
@use 'typography' as *;

$primary-drk: mat.define-palette(
    (
      0: white,
      50: var(--color-red-50),
      100: var(--color-red-100),
      200: var(--color-red-200),
      300: var(--color-red-300),
      400: var(--color-red-400),
      500: var(--color-red-500),
      600: var(--color-red-600),
      700: var(--color-red-700),
      800: var(--color-red-800),
      900: var(--color-red-900),
      contrast: (
        0: var(--color-surface-900),
        50: var(--color-surface-900),
        100: var(--color-surface-900),
        200: var(--color-surface-900),
        300: var(--color-surface-900),
        400: var(--color-surface-600),
        500: var(--color-surface-600),
        600: var(--color-surface-600),
        700: var(--color-surface-50),
        800: var(--color-surface-50),
        900: var(--color-surface-50),
        A100: var(--color-surface-900),
        A200: var(--color-surface-800),
        A400: var(--color-surface-600),
        A700: var(--color-surface-300),
      )
    )
);
$accent-drk: mat.define-palette(
    (
      0: white,
      50: var(--drk-blue-light),
      100: var(--drk-blue-light),
      200: var(--drk-blue-light),
      300: var(--drk-blue-main),
      400: var(--drk-blue-main),
      500: var(--drk-blue-main),
      600: var(--drk-blue-var),
      700: var(--drk-blue-var),
      800: var(--drk-blue-dark),
      900: var(--drk-blue-dark),
      contrast: (
        0: var(--color-surface-900),
        50: var(--color-surface-900),
        100: var(--color-surface-900),
        200: var(--color-surface-900),
        300: var(--color-surface-50),
        400: var(--color-surface-50),
        500: var(--color-surface-50),
        600: var(--color-surface-50),
        700: var(--color-surface-50),
        800: var(--color-surface-50),
        900: var(--color-surface-50),
        A100: var(--color-surface-900),
        A200: var(--color-surface-50),
        A400: var(--color-surface-50),
        A700: var(--color-surface-50),
      )
    )
);

$warning-drk: (
  0: white,
  50: var(--drk-red-light),
  100: var(--drk-red-light),
  200: var(--color-red-600),
  300: var(--color-red-600),
  400: var(--color-red-600),
  500: var(--color-red-600),
  600: var(--color-red-600),
  700: var(--color-red-700),
  800: var(--color-red-700),
  900: var(--color-red-700),
  A100: var(--color-orange-100),
  A200: var(--color-orange-200),
  A400: var(--color-orange-400),
  A700: var(--color-orange-700),
  contrast: (
    0: var(--color-surface-900),
    50: var(--color-surface-900),
    100: var(--color-surface-800),
    200: var(--color-surface-50),
    300: var(--color-surface-50),
    400: var(--color-surface-50),
    500: var(--color-surface-50),
    600: var(--color-surface-50),
    700: var(--color-surface-50),
    800: var(--color-surface-50),
    900: var(--color-surface-50),
    A100: var(--color-surface-900),
    A200: var(--color-surface-800),
    A400: var(--color-surface-600),
    A700: var(--color-surface-300),
  ),
);

$warn-drk: mat.define-palette($warning-drk);

$custom-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary-drk,
        accent: $accent-drk,
        warn: $warn-drk,
      ),
      typography: $kpi4me-typography
    )
);

.custom-theme {
  @include mat.all-component-colors($custom-theme);
  @include mat.all-component-densities(minimum);

  --frame: white;
  --original: var(--drk-blue-light);
  --background: white;
  --highlighted: var(--drk-blue-dark);
  --input-background: white;
  --text-dark: var(--drk-grey-dark);
  --text-bright: white;
  --text-gray: var(--drk-grey-dark);
  --menu-background: white;
  --kpi: white;
  --kpi-group: white;
  --header: white;
  --kpi-benchmark: white;
  --disabled-option: var(--drk-grey-main);
  --hover: var(--drk-red-dark);
  --background-blue: var(--drk-blue-light);
  --table-header-color: var(--drk-blue-light);
  --toolbar-text: var(--color-surface-900);
  --sidebar-text: var(--color-blue-800);
  --open-close-icon: var(--color-surface-500);
  --sidebar-hover: var(--color-surface-50);
  --sidebar-not-hover: var(--color-surface-500);
  --sidebar-hover-background: var(--color-blue-600);
  --divider: var(--color-surface-100);
  --side-menu-background: white;
  --toolbar-button-background: var(--drk-red-main);
  --select-background: var(--drk-grey-light);
  --toolbar-button-outline: var(--drk-grey-light);
  --toolbar-icon-background: var(--drk-grey-light);
  --toolbar-icon-outline: var(--drk-red-main);
  --toolbar-background: white;
  --toolbar-border: var(--drk-grey-light);
  --imported-cell: var(--color-surface-50);
  --snackbar-background: var(--drk-grey-dark);
  --snackbar-button: var(--drk-grey-main);
  --snackbar-text: var(--drk-grey-light);
  --default-value-text: rgba(0, 0, 0, 0.5);
  --help-background: var(--drk-red-light);

  // Bootstrap Calendar
  --bs-body-color: var(--text-dark);
  --bs-body-bg: white;
  --bs-tertiary-bg: var(--color-surface-50);
  --bs-link-color: var(--color-red-500);
  --bs-link-hover-color: var(--color-red-600);
  --bs-info: var(--color-red-300);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-red-500);

  .bg-primary {
    background-color: var(--color-red-500) !important;
  }

  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb(63, 75, 87)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
  }

  // Mat-menu
  --mat-menu-item-label-text-color: var(--text-dark);
  --mat-menu-item-icon-color: var(--text-dark);
  --mat-menu-item-hover-state-layer-color: #{mat.get-theme-color($custom-theme, primary, 500)};
  --mat-menu-item-focus-state-layer-color: #{mat.get-theme-color($custom-theme, primary, 500)};
  --mat-menu-container-color: var(--color-surface-50);

  .mat-mdc-menu-item:not([disabled]).cdk-program-focused,
  .mat-mdc-menu-item:not([disabled]).cdk-keyboard-focused,
  .mat-mdc-menu-item:not([disabled]).mat-mdc-menu-item-highlighted,
  .mat-mdc-menu-item:not([disabled]):hover {
    color: var(--text-bright);
    --mat-menu-item-icon-color: var(--text-bright);
  }

  // Checkbox
  .mat-mdc-checkbox.mat-primary,
  .mat-mdc-checkbox.mat-accent,
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: var(--text-bright);
  }

  // Buttons
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-raised-button.mat-accent,
  .mat-mdc-raised-button.mat-warn,
  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-unelevated-button.mat-accent,
  .mat-mdc-unelevated-button.mat-warn,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-fab.mat-accent,
  .mat-mdc-fab.mat-warn,
  .mat-mdc-mini-fab.mat-primary,
  .mat-mdc-mini-fab.mat-accent,
  .mat-mdc-mini-fab.mat-warn {
    color: var(--text-bright);
  }

  // Toolbar
  .mat-toolbar {
    color: var(--drk-grey-dark);
    background-color: white;

    mat-icon {
      color: var(--drk-red-main);
    }

    .user-icon {
      .mat-icon {
        color: var(--drk-grey-light);
      }
    }

    .granularity-button {
      color: var(--drk-red-main);
    }
  }

  // Button
  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: var(--text-bright);
  }

  // Slide-Toggle
  @mixin set-handle-colors($color) {
    --mdc-switch-selected-hover-handle-color: #{mat.get-theme-color($custom-theme, $color, 700)};
    --mdc-switch-selected-focus-handle-color: #{mat.get-theme-color($custom-theme, $color, 700)};
    --mdc-switch-selected-pressed-handle-color: #{mat.get-theme-color($custom-theme, $color, 700)};
  }

  .mat-mdc-slide-toggle {
    &.mat-accent {
      @include set-handle-colors(accent);
    }

    &.mat-warn {
      @include set-handle-colors(warn);
    }

    &.mat-primary {
      @include set-handle-colors(primary);
    }
  }

  // Date Picker
  kpi4me-date-picker {
    mat-button-toggle {
      color: var(--drk-red-main);

      --mat-standard-button-toggle-selected-state-text-color: var(--drk-red-main);
    }
  }

  // Progress Bar
  .mdc-linear-progress__buffer-bar {
    opacity: 0.4;
  }


  // confirm icon
  .mat-mdc-raised-button {
    --mdc-protected-button-label-text-color: var(--drk-grey-light);
    --mdc-protected-button-container-color: var(--drk-red-main);

    mat-icon {
      color: var(--color-surface-50);
    }
  }

  // Ag Grid
  --ag-selected-row-background-color: var(--drk-blue-light);

  .ag-row-hover.ag-row {
    --ag-row-hover-color: var(--drk-blue-light);
  }


  // tenant menu
  .tenant-menu {
    .default-tenant {
      background-color: var(--drk-red-main);
      color: var(--color-surface-50);
    }

    .current-tenant {
      background-color: var(--drk-red-light);
      color: var(--color-surface-50);

      .mat-icon {
        color: var(--color-surface-50);
      }
    }
  }

  .lm_controls .lm_popout {
    background-image: url(../assets/svg_new/splitscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_maximised .lm_controls .lm_maximise {
    background-image: url(../assets/svg_new/fullscreen_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .lm_popin .lm_icon {
    background-image: url(../assets/svg_new/edit_FILL0_wght300_GRAD0_opsz24.svg);
  }

  .red-icon {
    color: var(--drk-red-light) !important;

    .st5,
    .st8,
    .st6 {
      stroke: var(--drk-red-lightred-700) !important;
    }

    .st6,
    .st7 {
      fill: var(--drk-red-light);
    }
  }
}
