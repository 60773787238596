@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.ag-theme-alpine {
  --ag-checkbox-checked-color: var(--color-blue-800);

  @include ag-theme-alpine(
      (
        // use theme parameters where possible
        // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
        cell-horizontal-border: solid ag-derived(secondary-border-color),
        font-family: 'Poppins'
      )
  );

  .ag-header-cell {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 1px solid ag-param(secondary-border-color);
  }

  .ag-header-group-cell {
    border-right: 3px solid ag-param(secondary-border-color);
  }

  .ag-header-cell-label,
  .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-input-field-label.ag-label.ag-radio-button-label {
    color: var(--color-surface-600);
  }
}

.ag-theme-alpine {
  --ag-foreground-color: var(--text-dark);
  --ag-secondary-foreground-color: var(--text-dark);
  --ag-background-color: var(--background);
  --ag-header-foreground-color: var(--text-dark);
  --ag-header-background-color: var(--frame);

  --ag-font-family: 'Poppins';
}

.ag-theme-alpine .ag-row,
.ag-theme-alpine .ag-row-odd {
  background-color: var(--frame);
}

.ag-theme-alpine .ag-cell-inline-editing {
  height: 100%;
}

.ag-theme-alpine .ag-row-selected,
.ag-theme-alpine .created-row.ag-row-selected,
.ag-theme-alpine .changed-row.ag-row-selected {
  //box-shadow: inset 0px 0px 0px 3px var(--row-highlighted);
}

// Increase z-width of the popup editors to prevent the golden-layout resizing lines to react
.ag-popup-editor {
  //z-index: 1001;
}

.custom-group-header {
  padding-left: 0 !important;
}

.quarter-hours-header {
  &:not(:first-of-type) {
  }

  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resource-scheduler-row {
}

.no-borders {
  border-right: unset !important;
  font-weight: 700;
}

.thick-borders {
  border-bottom: 2px solid var(--color-surface-100) !important;
}

.white-background {
  background-color: white !important;
}

.blue-background {
  background-color: var(--background-blue) !important;
}

.bold-font {
  font-weight: 700;
}

.error-cell {
  background-color: var(--color-red-100);
}
