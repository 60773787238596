.p-multiselect {
  background: inherit;
  border-color: var(--color-surface-100);
  color: var(--color-surface-600);
}
.p-splitter-gutter {
  margin: 0 5px;
  background-color: var(--color-surface-100);
  z-index: 1000;

  .p-splitter-gutter-handle {
    background-color: var(--color-surface-200);
  }
}
