/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

///* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'themes';

// tree
//@import '@circlon/angular-tree-component/css/angular-tree-component.css';
@import './custom-bootstrap';
@import './custom-material';
@import './custom-prime-ng';

body {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

// overwrite syncfusion font
.e-control {
  font-family: "Poppins", sans-serif;
}

.icon-button-centered {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .mat-icon {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.flex, .flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-vertical, .align-center {
  display: flex;
  align-items: center;
}

.centered-horizontal, .align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-sm {
  gap: 0.5rem;
}

.gap-md {
  gap: 1rem;
}

.mat-icon.smaller-icon {
  width: 20px;
  height: 20px;
  display: flex;
}


.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
  font: 400 14px / 20px Poppins, "Helvetica Neue", sans-serif;
}

// fix global styles
label {
  margin-bottom: 0;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto/roboto400.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto/roboto500.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto/Roboto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto/Roboto-Thin.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/roboto/Roboto-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/roboto/Roboto-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/roboto/Roboto-Italic.ttf') format('ttf');
}


@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

.unstyled-button {
  all: unset;
  &:focus {
    outline: revert;
  }
}

